import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import { systemModule } from '@/store/modules/system';
import { detailsModule } from '@/store/modules/details';
var NewsDetails = (function (_super) {
    __extends(NewsDetails, _super);
    function NewsDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NewsDetails.prototype.mounted = function () {
        this.getNewsObj(this.id);
        detailsModule.getNewsList({
            page: 1,
            rows: 999,
            id: '',
            stick: 1
        });
    };
    Object.defineProperty(NewsDetails.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsDetails.prototype, "newsObj", {
        get: function () {
            return detailsModule.newsObj;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewsDetails.prototype, "newsStick", {
        get: function () {
            return detailsModule.newsStick;
        },
        enumerable: false,
        configurable: true
    });
    NewsDetails.prototype.getNewsObj = function (id) {
        detailsModule.getNewsObj(id);
        document.body.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
        });
    };
    __decorate([
        Prop()
    ], NewsDetails.prototype, "id", void 0);
    NewsDetails = __decorate([
        Component({
            components: { CustomHead: CustomHead }
        })
    ], NewsDetails);
    return NewsDetails;
}(Vue));
export default NewsDetails;
